import styles from "./Table.module.css"
import Header from '../../Components/Header'
import { Table, Container, Button } from 'react-bootstrap'
import { useState } from 'react'
import { Link } from "react-router-dom"

const TablePage = (props: any) => {
  const [tableData] = useState(JSON.parse(localStorage.getItem('formData') || '[]'))
  const [checkedAll, setCheckedAll] = useState(false)

  console.log(props)


  const handleCheckedAll = () => {
    setCheckedAll(!checkedAll)
  }


  const handleDeleteAll = () => {
    localStorage.removeItem('formData')
    window.location.reload()
  }

  return (
    <div>
      <Header showSearch={true} handleSearch={props.handleSearch} searchValue={props.searchValue} />
      {
        tableData.length > 0 ? (
          <>
            <Container className={styles.tableContainer}>
              <p className="my-3 fs-5">
                {
                  `${tableData.length} Records Found`
                }
              </p>
              <Table bordered hover>
                <thead>
                  <tr className="text-center">
                    <th><input type="checkbox" checked={checkedAll} onChange={handleCheckedAll} name="check" id="id" /></th>
                    <th>Form details</th>
                    <th>Customer Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Please rate the quality of service you received from your host</th>
                    <th>Please</th>
                  </tr>
                </thead>
                {
                  tableData.filter((item: any) => {
                    if (props.searchValue === "") {
                      return item
                    }
                    return item.name.toLowerCase().includes(props.searchValue.toLowerCase())
                  }).map((data: any, index: number) => (
                    <tbody key={index}>
                      <tr className="text-center">
                        <td><input type="checkbox" checked={checkedAll} onChange={handleCheckedAll} id="id2" /></td>
                        <td>
                          <Link to="/table">View Details</Link>
                        </td>
                        <td>{data.name}</td>
                        <td>{data.email}</td>
                        <td>{data.phone}</td>
                        <td>{data.rating1}</td>
                        <td></td>
                      </tr>
                    </tbody>
                  ))
                }
                {/* <tbody>
                  <tr className="text-center">
                    <td><input type="checkbox" name="check" id="id" /></td>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                  </tr>
                </tbody> */}
              </Table>
            </Container>
            <Container className={`${styles.buttonContainer} d-flex justify-content-end pe-4`}>
              <Button
                className="px-4 mb-3"
                variant="danger"
                onClick={
                  () => {
                    if (!checkedAll) {
                      return
                    }
                    handleDeleteAll()
                  }
                }
              >
                Delete
              </Button>
            </Container>
          </>
        ) : (
          <h3 className="text-primary text-center my-4">
            No data found
          </h3>
        )
      }
    </div>
  )
}

export default TablePage