import { Container, Button } from "react-bootstrap"
import { AiFillCheckCircle } from "react-icons/ai"
import { useNavigate } from "react-router-dom"

const Thankmessage = () => {
  const navigate = useNavigate()
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <AiFillCheckCircle size={100} color="green" />
        <h3
          className="text-primary mt-4 mb-2"
        >
          Thank you for providing the feedback
        </h3>
        <h6
          className="text-secondary mb-4"
        >
          We will work towards improving your experience
        </h6>
        <Button
          variant="success"
          className="p-0 px-3 py-2 ms-2 px-5"
          onClick={() => { navigate("/table") }}
        >
          Close
        </Button>
      </div>
    </Container>
  )
}

export default Thankmessage