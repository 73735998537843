import styles from './Form.module.css'
import Header from '../../Components/Header'
import { Button, Container } from 'react-bootstrap'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { useState } from 'react'

import { useNavigate } from 'react-router-dom'

const FormPage = (props:any) => {
  const navigate = useNavigate()

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    rating1: '',
    rating2: '',
    rating3: '',
    rating4: '',
  })

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    rating1: false,
    rating2: false,
    rating3: false,
    rating4: false,
  })

  const onChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const onSubmit = async (event: any) => {
    event.preventDefault()
    if (!formData.name || !formData.email || !formData.phone || !formData.rating1 || !formData.rating2 || !formData.rating3 || !formData.rating4) {
      setErrors({
        name: !formData.name,
        email: !formData.email,
        phone: !formData.phone,
        rating1: !formData.rating1,
        rating2: !formData.rating2,
        rating3: !formData.rating3,
        rating4: !formData.rating4,
      })
      return
    }

    if (formData.phone.length !== 10) {
      setErrors({
        ...errors,
        phone: true
      })
      return
    }

    const oldData = JSON.parse(localStorage.getItem('formData') || '[]')
    localStorage.setItem('formData', JSON.stringify([...oldData, formData]))

    setFormData({
      name: '',
      email: '',
      phone: '',
      rating1: '',
      rating2: '',
      rating3: '',
      rating4: '',
    })
    navigate('/thankyou')
  }



  return (
    <div>
      <Header showSearch={false} handleSearch={()=>{}} searchValue={props.serachValue}/>
      <Container>
        <form className={styles.form} onSubmit={onSubmit}>
          <div className={styles.inputGroup}>
            <div className={styles.inputFields}>
              <label htmlFor="name" className={`${styles.inputLabel} fw-bold`}>Customer Name</label>
              <input type="text" id="name" name='name' onChange={onChange} value={formData.name} placeholder='Eg. Abhishek' />
              {
                errors.name && (
                  <div className={`${styles.error} px-1 text-danger`}>
                    <span className={styles.errorIcons}>
                      <AiOutlineExclamationCircle size={13} />
                    </span> {" "}
                    <span className={styles.errorMessage}>
                      Please enter the value for above field
                    </span>
                  </div>
                )
              }
            </div>

            <div className={styles.inputFields}>
              <label htmlFor="name" className={`${styles.inputLabel} fw-bold`}>Email</label>
              <input type="text" id="email" name='email' onChange={onChange} value={formData.email} placeholder='Eg. ab@gmail.com' />
              {
                errors.email && (
                  <div className={`${styles.error} px-1 text-danger`}>
                    <span className={styles.errorIcons}>
                      <AiOutlineExclamationCircle size={13} />
                    </span> {" "}
                    <span className={styles.errorMessage}>
                      Please enter the value for above field
                    </span>
                  </div>
                )
              }
            </div>
          </div>

          <div className={styles.inputGroup}>
            <div className={styles.inputFields}>
              <label htmlFor="name" className={`${styles.inputLabel} fw-bold`}>Phone</label>
              <div className="d-flex w-100">
                <select>
                  <option>🇮🇳</option>
                </select>
                <input type="number" className="w-100" id="number" name='phone' onChange={onChange} value={formData.phone} placeholder='999999999' />
              </div>
              {
                errors.phone && (
                  <div className={`${styles.error} px-1 text-danger`}>
                    <span className={styles.errorIcons}>
                      <AiOutlineExclamationCircle size={13} />
                    </span> {" "}
                    <span className={styles.errorMessage}>
                      Please enter the value for above field
                    </span>
                  </div>
                )
              }
            </div>
            <div className={styles.inputFields}>
            </div>
          </div>

          <div className={styles.inputGroup}>
            <div className={styles.inputFields}>
              <label htmlFor="name" className={`${styles.inputLabel} fw-bold`}>
                Please rate the quality of the service you received from your host.
              </label>


              <div className={styles.radioGroup}>
                <div className={styles.radioGroup}>
                  <input type="radio" id='Excellent' onChange={onChange} name="rating1" value="Excellent" />
                  <label htmlFor="Excellent"  >Excellent</label>
                </div>

                <div className={styles.radioGroup}>
                  <input type="radio" id='Good' onChange={onChange} name="rating1" value="Good" />
                  <label htmlFor="Good"  >Good</label>
                </div>

                <div className={styles.radioGroup}>
                  <input type="radio" id='Fair' onChange={onChange} name="rating1" value="Fair" />
                  <label htmlFor="Fair"  >Fair</label>
                </div>

                <div className={styles.radioGroup}>
                  <input type="radio" id='Bad' onChange={onChange} name="rating1" value="Bad" />
                  <label htmlFor="Bad"  >Bad</label>
                </div>

              </div>

              {
                errors.rating1 && (
                  <div className={`${styles.error} px-1 text-danger`}>
                    <span className={styles.errorIcons}>
                      <AiOutlineExclamationCircle size={13} />
                    </span> {" "}
                    <span className={styles.errorMessage}>
                      Please enter the value for above field
                    </span>
                  </div>
                )
              }

            </div>

            <div className={styles.inputFields}>
              <label htmlFor="name" className={`${styles.inputLabel} fw-bold`}>
                Please rate the quality of your beverage.
              </label>

              <div className={styles.radioGroup}>
                <div className={styles.radioGroup}>
                  <input type="radio" id='Excellent2' onChange={onChange} name="rating2" value="Excellent" />
                  <label htmlFor="Excellent2"  >Excellent</label>
                </div>

                <div className={styles.radioGroup}>
                  <input type="radio" id='Good2' onChange={onChange} name="rating2" value="Good" />
                  <label htmlFor="Good2"  >Good</label>
                </div>

                <div className={styles.radioGroup}>
                  <input type="radio" id='Fair2' onChange={onChange} name="rating2" value="Fair" />
                  <label htmlFor="Fair2"  >Fair</label>
                </div>

                <div className={styles.radioGroup}>
                  <input type="radio" id='Bad2' onChange={onChange} name="rating2" value="Bad" />
                  <label htmlFor="Bad2"  >Bad</label>
                </div>

              </div>

              {
                errors.rating2 && (
                  <div className={`${styles.error} px-1 text-danger`}>
                    <span className={styles.errorIcons}>
                      <AiOutlineExclamationCircle size={13} />
                    </span> {" "}
                    <span className={styles.errorMessage}>
                      Please enter the value for above field
                    </span>
                  </div>
                )
              }

            </div>

          </div>

          <div className={styles.inputGroup}>
            <div className={styles.inputFields}>
              <label htmlFor="name" className={`${styles.inputLabel} fw-bold`}>
                Was our restaurant clean?
              </label>

              <div className={styles.radioGroup}>
                <div className={styles.radioGroup}>
                  <input type="radio" id='Excellent3' onChange={onChange} name="rating3" value="Excellent" />
                  <label htmlFor="Excellent3"  >Excellent</label>
                </div>

                <div className={styles.radioGroup}>
                  <input type="radio" id='Good3' onChange={onChange} name="rating3" value="Good" />
                  <label htmlFor="Good3"  >Good</label>
                </div>

                <div className={styles.radioGroup}>
                  <input type="radio" id='Fair3' onChange={onChange} name="rating3" value="Fair" />
                  <label htmlFor="Fair3"  >Fair</label>
                </div>

                <div className={styles.radioGroup}>
                  <input type="radio" id='Bad3' onChange={onChange} name="rating3" value="Bad" />
                  <label htmlFor="Bad3"  >Bad</label>
                </div>

              </div>

              {
                errors.rating3 && (
                  <div className={`${styles.error} px-1 text-danger`}>
                    <span className={styles.errorIcons}>
                      <AiOutlineExclamationCircle size={13} />
                    </span> {" "}
                    <span className={styles.errorMessage}>
                      Please enter the value for above field
                    </span>
                  </div>
                )
              }

            </div>

            <div className={styles.inputFields}>
              <label htmlFor="name" className={`${styles.inputLabel} fw-bold`}>
                Please rate your overall dining experiece.
              </label>

              <div className={styles.radioGroup}>
                <div className={styles.radioGroup}>
                  <input type="radio" id='Excellent4' onChange={onChange} name="rating4" value="Excellent" />
                  <label htmlFor="Excellent4"  >Excellent</label>
                </div>

                <div className={styles.radioGroup}>
                  <input type="radio" id='Good4' onChange={onChange} name="rating4" value="Good" />
                  <label htmlFor="Good4"  >Good</label>
                </div>

                <div className={styles.radioGroup}>
                  <input type="radio" id='Fair4' onChange={onChange} name="rating4" value="Fair" />
                  <label htmlFor="Fair4"  >Fair</label>
                </div>

                <div className={styles.radioGroup}>
                  <input type="radio" id='Bad4' onChange={onChange} name="rating4" value="Bad" />
                  <label htmlFor="Bad4"  >Bad</label>
                </div>

              </div>
              {
                errors.rating4 && (
                  <div className={`${styles.error} px-1 text-danger`}>
                    <span className={styles.errorIcons}>
                      <AiOutlineExclamationCircle size={13} />
                    </span> {" "}
                    <span className={styles.errorMessage}>
                      Please enter the value for above field
                    </span>
                  </div>
                )
              }
            </div>
          </div>
          <div className="d-flex justify-content-end w-100 px-5 ">
            <Button
              type="submit"
              variant="success"
              className={`${styles.submitButton} px-3`}

            >
              Submit Review
            </Button>
          </div>
        </form>
      </Container>
    </div>
  )
}

export default FormPage