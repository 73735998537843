import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import FormPage from './App/Containers/Form/FromPage';
import TablePage from './App/Containers/Table/TablePage';
import Thankmessage from './App/Components/Thankmessage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState } from "react"

function App() {
  const [searchValue, setSearchValue] = useState('');
  const handleSearch = (e:any) => {
    setSearchValue(e.target.value);
  }
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<FormPage />} />
          <Route path="/table" element={<TablePage handleSearch={handleSearch} searchValue={searchValue}/>} />
          <Route path="/thankyou" element={<Thankmessage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
