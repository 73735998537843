import { Navbar, Container, Form, Button } from 'react-bootstrap'
import { BsSearch } from 'react-icons/bs'
import { GrRefresh } from 'react-icons/gr'
import { useNavigate } from 'react-router-dom'

interface HeaderProps {
  showSearch: boolean,
  handleSearch: (e: any) => void,
  searchValue: string,
}

const Header = (props: HeaderProps) => {
  const navigate = useNavigate()
  return (
    <Navbar className="shadow-sm bg-white">
      <Container>
        <Navbar.Brand onClick={() => navigate("/")} className='fw-bold'>Aromatic Bar</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          {
            props.showSearch && (
              <>
                <div className="input">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="d-inline w-auto"
                    value={props.searchValue}
                    onChange={props.handleSearch}
                  />
                  <BsSearch className="searchIcon" />
                </div>
                <button
                  className='border border-grey py-1 px-2'
                >
                  <GrRefresh
                    onClick={() => window.location.reload()}
                  />
                </button>
                <Button
                  variant="success"
                  className="p-0 px-3 py-1 ms-2"
                  onClick={() => navigate('/')}
                >
                  Add New
                </Button>
              </>
            )
          }
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header